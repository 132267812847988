<template>
    <div class="container">
        <h1>{{$t('app.siteTitle')}}</h1>

        <div class="p-4 bg-white rounded shadow text-center">
            <p class="h3">
                <b-icon icon="check-square" class="mr-4 text-success" /> {{ $t('app.dataHasBeenSent') }}
            </p>
            <p v-if="false" class="mb-0 mt-5">
                <b-btn
                    variant="primary"
                    :to="{ name: 'Index' }"
                >
                    {{$t('app.backToHome')}}
                </b-btn>
            </p>
        </div>
    </div>
</template>

<script>

export default {
    name: 'SessionDataSent'
}
</script>
