<template>
    <div class="p-4 bg-white rounded shadow text-center mt-4">
        <p class="h3 mt-3">
            <b-icon icon="check-square" class="mr-4 text-success" /> {{ $t('app.dataHasBeenSent') }}
        </p>
    </div>
</template>

<script>

export default {
    name: 'DataCheckupSent'
}
</script>
